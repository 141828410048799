.NewFlightSegmentForm-Row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.NewFlightSegmentForm-Leg {
  padding: 10px;
}

.NewFlightSegmentForm-Leg:nth-child(even) {
  background-color: #efefef;
}

.NewFlightSegmentForm-SuggestionField {
  background-color: transparent;
  border: 0px;
  box-shadow: none;
  padding: 6px 2px;
}

.NewFlightSegmentForm-SuggestionField:focus {
  background-color: white;
  border: 1px solid #ccc;
  padding: 6px 12px;
}
